*,
*:before,
*:after {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  font-family: var(--tech-font-1);
}

html,
body,
app-root {
  display: block;
  width: 100%;
  height: 100%;
  min-width: 320px;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

input,
textarea,
button {
  outline: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

dialog {
  border: none;
  padding: 0;
  margin: 0;
  position: static;
  color: #000000;
}

// Width
::-webkit-scrollbar {
  width: 0px;
}

:hover::-webkit-scrollbar {
  width: 5px;
}

// Track
::-webkit-scrollbar-track {
  background: transparent;
  border-right: 1px solid transparent;
}

// Handle
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-right: 1px solid transparent;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #868282;
}

svg {
  display: block;
  object-fit: contain;
  width: 50vw;
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}
