// The min screen is 320px.
// Mobile first

@mixin mobile_m {
  @media screen and (min-width: 375px) {
    @content;
  }
}

@mixin mobile_l {
  @media screen and (min-width: 425px) {
    @content;
  }
}

@mixin table_m {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop_m {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop_m {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin splitCustom($min: 1440px) {
  @media screen and (min-width: $min) {
    @content;
  }
}

%textTitleBg {
  font-weight: 700;
  font-size: 3rem;
}
%textTitleMedium {
  font-weight: 700;
  font-size: 2rem;
}

%textDescriptionMedium {
  font-weight: 400;
  font-size: 1rem;
}

%btnAction {
  padding: 7px 14px;
  display: grid;
  place-items: center;
  border-radius: 0.3rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  text-decoration: none;
  box-shadow: 0 0 0.2rem #00000033;
  width: max-content;
  transition: all linear 250ms;
  &::first-letter {
    text-transform: uppercase;
  }

  &:not(:disabled) {
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
}
%atech {
  width: max-content;
  font-weight: 500;
  font-size: 1rem;
  color: #2645f1;
}
%authTemplate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #1e2129;
  color: #94a3b8;
}
