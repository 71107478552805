/* You can add global styles to this file, and also import other style files */
@use "src/scss/mixin.scss" as mixin;

.auth-container {
  border-radius: 0.2rem;
  // box-shadow: 0 0 0.3rem #00000033;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
  width: 20rem;
  margin: 0.5rem;
  padding: 2rem;
  min-height: 700px;
  gap: 0.4rem;
  &__logo {
    aspect-ratio: 16 / 9;
    width: 10rem;
    // box-shadow: 0 0 0.3rem #00000033;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img,
    source {
      object-fit: contain;
      width: 100%;
    }
  }
}

.auth-title {
  @extend %textTitleMedium;
  text-align: center;
}
.auth-form {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
}

.auth-btn {
  @extend %btnAction;
  border-radius: 0.3rem;
  color: #94a3b8;
  box-shadow: 0 0 0.1rem #94a3b8;
  min-width: 100%;

  &:focus {
    box-shadow: 0 0 0.3rem #94a3b8;
  }
  &:disabled {
    background: #dddddd;
  }
}
.tech-ancla {
  @extend %atech;
}

.toolbar-btn {
  @extend %btnAction;
  border-radius: 0.1rem;
  color: #94a3b8;
  aspect-ratio: 1 / 1;
  width: 1.5rem;
  padding: 0.1rem;
  box-shadow: 0 0 0.1rem #94a3b8;

  &:focus {
    box-shadow: 0 0 0.3rem #94a3b8;
  }
  &:disabled {
    background: #dddddd;
  }
}
.toolbar-short-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.375rem;
}
.toolbar-short-menu-btn {
  @extend %btnAction;
  border-radius: 0.1rem;
  color: #94a3b8;
  aspect-ratio: 1 / 1;
  width: 1.5rem;
  padding: 0.1rem;
  box-shadow: 0 0 0.1rem #94a3b8;

  &:focus {
    box-shadow: 0 0 0.3rem #94a3b8;
  }
  &:disabled {
    background: #dddddd;
  }
  &.user-online {
    color: var(--tech-user-card-online);
  }
  &.user-buse {
    color: var(--tech-user-card-buse);
  }
  &.user-not-visible {
    color: var(--tech-user-card-not-visible);
  }
}

.toolbar-wrpaper-logo {
  display: grid;
  place-content: center;
  filter: drop-shadow(0 0 0.4rem #00000022);
  aspect-ratio: 16 / 9;
  width: 7rem;
  &__logo {
    width: 100%;
    object-fit: contain;
  }
}

.btn--action-table {
  width: 32px;
  height: 32px;
  aspect-ratio: 1 / 1;
  width: 2rem;
  border-radius: 3px;
  padding: 0.2rem;
  cursor: pointer;
  box-shadow: 0 0 0.3rem #00000033;
  transform: scale(0.9);
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1);
  &:hover {
    color: #2468a0;
    transform: scale(1);
  }
  &:active {
    transform: scale(0.8);
  }
}
